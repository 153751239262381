import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import airindia1 from "../Images/airindia/airindia1.png";
import airindia2 from "../Images/airindia/airindia2.png";
import airindia3 from "../Images/airindia/airindia3.png";

function Airindia() {
  const navigationProps = {
    previousLink: "/Atlantic",
    nextLink: "/Cnil",
  };
  return (
    <div className="casestudy3">
      <div style={{ marginTop: "100px" }}></div>
      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2022</p>
          <p>Location : New Delhi - India</p>
          <p>
            Fictive project : <a href="https://www.airindia.fr/">Air India</a>
          </p>
        </div>

        <h1>✈ Booking flight</h1>

        <h2>UI Design</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={airindia1}></img>
        <div style={{ marginTop: "24px" }}></div>

        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>
            <p>
              In 2022, I undertook a fictive project to redesign the mobile
              interface of Air India. The goal was to enhance the user
              experience by developing a UI kit aligned with the brand
              guidelines and designing various screens integral to the user
              journey, including onboarding, flight booking, and payment.
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={airindia2}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={airindia3}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Airindia;
