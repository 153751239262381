import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Style/Header.css";

function Header() {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setIsHeaderVisible(false);
    } else {
      setIsHeaderVisible(true);
    }
  };

  const isActive = (path) => {
    return location.pathname === path ? "active-green" : "";
  };

  return (
    <div className={`header ${isHeaderVisible ? "" : "hidden"}`}>
      <div className="item__header">
        <div className={`work__bg ${isActive("/")}`}>
          <a href="/">Home</a>
        </div>
        <div className={`about__bg ${isActive("/About")}`}>
          <a href="/About">About</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
