import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import eram1 from "../Images/eram/eram1.jpg";
import eram2 from "../Images/eram/eram2.jpg";
import eram3 from "../Images/eram/eram3.jpg";
import eram4 from "../Images/eram/eram4.gif";

import { ArrowForward } from "@mui/icons-material";

function Eram() {
  const navigationProps = {
    previousLink: "/Sncf",
    nextLink: "/Indemnite",
  };

  return (
    <div className="casestudy4">
      <div style={{ marginTop: "100px" }}></div>

      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2018</p>
          <p>Location : Nantes - France</p>
          <p>
            Client : <a href="http://eram.fr/">Eram</a>
          </p>
        </div>

        <h1>👟 Connected shoes</h1>

        <h2>UX Research</h2>
        <div style={{ marginTop: "100px" }}></div>

        <img src={eram4}></img>
        <div style={{ marginTop: "24px" }}></div>

        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>

            <p>
              Eram launched a line of connected shoes that allow users to change
              their color via a smartphone app. Working with two other
              designers, our task was to brainstorm and develop new features for
              this innovative product.
            </p>

            <h3>🎯 Objectives</h3>
            <p>01. Identify new use cases related to gaming for the connected shoes.</p>
            <p>02. Develop a quick prototype to demonstrate a proof of concept for these features.</p>
            <div style={{ marginTop: "24px" }}></div>

            <h3>🔎 Research methodology</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>
            <b>  Online Survey:</b> Conducted with 45 students aged 18-25 to gather insights and preferences related to the connected shoes.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
      <b>     Interviews: </b> Engaged with employees and customers at the Eram store in Nantes to understand their experiences and gather feedback.
              </p>
            </div>
            <div style={{ marginTop: "24px" }}></div>

            <h3>🌞 Design and deliverables</h3>
            <p>
           <b> Site Map Creation:</b> Developed a comprehensive site map to outline the product’s structure and features.
            </p>
            <p><b>Quick Prototype:</b> Created a prototype to showcase potential game features that can be enhanced by the connected shoes using augmented reality.</p>

            <img src={eram3}></img>
            <p>
              The games are the following : Drawing on the floor, karting,
              dance, bowling and golf.
            </p>
            <img src={eram2}></img>

            <p><b>Onboarding Interface:</b> Designed an intuitive onboarding interface to guide users through the initial setup and feature exploration of the connected shoes.</p>
            <img src={eram1}></img>
            <div style={{ marginTop: "24px" }}></div>

            <p>🖐 Collaboration : ERAM and L'Ecole de Design Nantes Atlantique</p>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        </div>
      </div>

      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Eram;
