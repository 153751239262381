import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import sagan1 from "../Images/mesindemnites/sagan1.jpg";
import sagan2 from "../Images/mesindemnites/sagan2.jpg";
import sagan3 from "../Images/mesindemnites/sagan3.jpg";
import sagan4 from "../Images/mesindemnites/sagan4.jpg";
import sagan5 from "../Images/mesindemnites/sagan5.jpg";
import sagan6 from "../Images/mesindemnites/sagan6.jpg";

import { ArrowForward } from "@mui/icons-material";

function Indemnite() {
  const navigationProps = {
    previousLink: "/Eram",
    nextLink: "/Atlantic",
  };

  return (
    <div className="casestudy7">
      <div style={{ marginTop: "100px" }}></div>
      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2020</p>
          <p>Location : Paris - France</p>
          <p>
            Client : <a href="http://mesindemnites.com/">mesindemnites.com</a>
          </p>
        </div>
        <div className="title__maxsize">
          <h1>💼 mesindemnites.com</h1>
        </div>

        <h2>UX Design</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={sagan6}></img>
        <div style={{ marginTop: "24px" }}></div>
        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>

            <p>
              Mesindemnites.com is a French company based in Paris, specializing
              in labor law. The platform aims to assist both employees and
              employers in accurately calculating termination payments. My role
              involved redesigning the website to enhance user experience and
              increase conversion rates.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🎯 Objective</h3>

            <p>01. Analyze user behavior and interactions with the platform.</p>
            <p>02. Enhance the existing service based on identified user needs.</p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔎 Interviews & persona</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>Employers :</p>
            </div>

            <p>'look forward to the future with more peace of mind'</p>
            <p>'Peace of mind'</p>
            <p>'Confidence in negotiations'</p>

            <div className="arrow__title">
              <ArrowForward />
              <p>Employees :</p>
            </div>

            <p>'A humanly complicated moment'</p>
            <p>'Labour law risk'</p>
            <p>'Labour law risk'</p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
              Based on these 10 interviews, we developed 10 distinct personas representing different types of users, each with unique needs and motivations.
              </p>
            </div>

            <img src={sagan1}></img>
            <div style={{ marginTop: "24px" }}></div>
            <h3>👨‍🦱 user scenarios</h3>

            <p>
            We imagined how users would navigate through the service, from the initial need for calculation, searching online, filling out forms, to ultimately using the service. Each user scenario outlines the user’s thoughts, challenges, emotions, and opportunities at each stage.


            </p>
            <p>
            Through our research, we discovered that employers and employees approach the topic of dismissal differently. This insight led us to create two separate user flows, tailored to each group, with an adapted tone of voice in the UX writing.
            </p>

            <img src={sagan2}></img>
            <div style={{ marginTop: "24px" }}></div>
            <h3>📍 User flow</h3>

            <p>
            We designed the user journey to complete the termination payment calculation, starting from the landing page and progressing through a series of steps to the final action. A secondary user flow was created to address user privacy concerns.
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={sagan3}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={sagan4}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>💻 Wireframes</h3>

            <p>
            Developed detailed wireframes for all key pages, ensuring that each interface element aligns with the identified user flows.
            </p>

            <img src={sagan5}></img>
            <div style={{ marginTop: "24px" }}></div>
            <p>🖐 Collaboration : This project was executed in collaboration with A. Sabbe-Ferri, ensuring that the redesign met both user and business needs.</p>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        </div>
      </div>

      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Indemnite;
