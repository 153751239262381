import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import image1 from "../Images/sncf/image1.jpg";
import image2 from "../Images/sncf/image2.jpg";
import image3 from "../Images/sncf/image3.jpg";
import image4 from "../Images/sncf/image4.jpg";
import design from "../Images/sncf/design.jpg";
import photo from "../Images/sncf/photo.jpg";
import photo1 from "../Images/sncf/photo1.jpg";
import photo2 from "../Images/sncf/photo2.jpg";

import { ArrowForward } from "@mui/icons-material";

function Sncf() {
  const navigationProps = {
    previousLink: "/Magnolia",
    nextLink: "/Eram",
  };
  return (
    <div className="Sncf">
      <div style={{ marginTop: "100px" }}></div>
      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2021</p>
          <p>Location : Nantes - France</p>
          <p>
            Client : {""}
            <a href="https://www.sncf.com/en">SNCF</a>
          </p>
        </div>

        <h1>🚐 Autonomous shared mobility</h1>

        <h2>UX Research</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={design}></img>
        <div style={{ marginTop: "24px" }}></div>
        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>

            <p>
              SNCF, France's national state-owned railway company, is developing
              a new autonomous shared mobility service, revitalizing unused
              train lines for modern transportation. Our team of four designers
              focused on the test line between Nantes (Doulon) and Carquefou,
              covering 7 km with approximately 6 to 10 stations on a former
              train route that has been inactive for some time.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🎯 Objectives</h3>

            <p>01. Observe the behaviors and needs of staff and passengers.</p>
            <p>
              02. Study the modularity of the vehicle and the potential for
              service personalization.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔎 Research methodology</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                Conducted 43 contextual interviews (ranging from 3 to 30
                minutes) in the Nantes area along the projected line.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                Performed in situ observations at streetcar and bus stops,
                shops, public squares, bars, cafes, and the SNCF track.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                Captured over 150 photos during the field study to document
                findings.
              </p>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={photo1}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={photo2}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔑 Observations and field research</h3>

            <p>
              "We have a lot of activity with the long-distance buses" - Bar /
              Restaurant manager - Nantes
            </p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                Noted a high volume of people with luggage between the SNCF
                station and the Haluchère bus station.
              </p>
            </div>

            <p>
              "The human presence is reassuring in transport when I travel" -
              Tramway user
            </p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                Observed that users feel more secure with visible human presence
                in transportation settings.
              </p>
            </div>

            <p>
              Noted that travelers often focus on their personal belongings and
              value security regarding their possessions.
            </p>

            <img src={photo}></img>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🌞 Design and deliverables</h3>

            <p>
              <b> Service Adequacy:</b> The research with SNCF staff and
              passengers highlighted the alignment of the new transportation
              service with future passenger needs.
            </p>

            <p>
              <b> Innovative and Scalable Transportation:</b> Developed a
              transportation solution that is adaptable to diverse user needs.
            </p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b>Vehicle Modularity:</b> Designed to offer an ergonomic
                experience for various user groups, including individuals,
                groups, people with reduced mobility, and families with young
                children. The service is adaptable to different space and device
                requirements.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b>Service Customization: </b> Offered customizable options such
                as on-demand stops, reservation/privatization, and preferences
                for traveling with individuals of the same gender.
              </p>
            </div>

            <div style={{ marginTop: "24px" }}></div>
            <h3>🚐 User scenario</h3>

            <p>
              Focused on the onboarding process for the autonomous vehicle and
              devised various usage scenarios to identify key stages in the user
              journey.
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={image1}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={image2}></img>
              </div>
            </div>

            <p>
          <b>Ticket Reservation:</b> Users can reserve tickets via an interactive terminal or smartphone. The ticket is activated only upon entering the service through a security portal.
            </p>
          </div>
          <div className="image_DG">
            <div className="imggauche">
              <img src={image3}></img>
            </div>
            <div className="mid"></div>
            <div className="imgdroite">
              <img src={image4}></img>
            </div>
          </div>
          <div style={{ marginTop: "24px" }}></div>
          <p>🖐 Collaboration : SNCF and L'École de Design Nantes Atlantique to bring this project to fruition.</p>
          <div style={{ marginTop: "100px" }}></div>
        </div>
      </div>

      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Sncf;
