import "../Style/Home.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Gallery from "../Components/Gallery";

function Home() {
  return (
    <div className="home">
      <Header />
      <div class="presentation">
        <h1>
          Hello, my name is Alexis, I am a product designer from France 👋
        </h1>
        <a href="/About">More about me</a>
      </div>

      <Gallery />

      <Footer />
    </div>
  );
}

export default Home;
