import React from "react";
import "../Style/Casestudy.css";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";

import atlantic1 from "../Images/atlantic/atlantic1.jpg";
import atlantic2 from "../Images/atlantic/atlantic2.jpg";
import atlantic3 from "../Images/atlantic/atlantic3.jpg";
import atlantic4 from "../Images/atlantic/atlantic4.jpg";
import atlantic5 from "../Images/atlantic/atlantic5.jpg";
import atlantic6 from "../Images/atlantic/atlantic6.jpg";
import atlantic7 from "../Images/atlantic/atlantic7.jpg";
import atlantic8 from "../Images/atlantic/atlantic8.jpg";
import atlantic9 from "../Images/atlantic/atlantic9.jpg";
import atlantic10 from "../Images/atlantic/atlantic10.jpg";
import atlanticA from "../Images/atlantic/atlanticA.jpg";
import atlanticgif from "../Images/atlantic/atlanticgif.gif";

import { ArrowForward } from "@mui/icons-material";

function Atlantic() {
  const navigationProps = {
    previousLink: "/Indemnite",
    nextLink: "/Airindia",
  };

  return (
    <div className="casestudy1">
      <div style={{ marginTop: "100px" }}></div>

      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2020</p>
          <p>Location : Pune - India</p>
          <p>
            Client : <a href={atlantic1}>Groupe Atlantic</a>
          </p>
        </div>

        <h1>⚡ Immersive rod</h1>
        <h2>UX Research</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={atlanticA}></img>
        <div style={{ marginTop: "24px" }}></div>
        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>

            <p>
              Groupe Atlantic, a French company specializing in heating,
              ventilation, and air conditioning, aimed to expand its presence in
              the Indian water heater market. Along with a team of 5 designers,
              I worked on prototyping a new thermal product, focusing on water
              heater usage for bathing and cleaning in Indian homes.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🎯 Objectives</h3>

            <p>
              01. Understand the usage patterns and behaviors of water heater users in Indian households.
              <br></br>
              02. Analyze competitors and identify new market opportunities.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔎 Research methodology</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>
              Conducted <b>30 contextual interviews</b> with various households and key stakeholders, including hospitals and restaurants.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
              Performed <b>on-field observations</b> in Indian homes to study water heater installations.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
              Undertook <b>desk research</b> to understand the context of hot water usage in India and the water heater market.
              </p>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={atlantic1}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={atlantic6}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>📦 Existing products</h3>
            <p>
            In India, taking a shower with a bucket is a common practice. This method involves using a bucket to store heated water and a plastic mug to pour water over the body. The most widely used product for heating water is an electric immersion rod, which heats the water directly in the bucket.
            </p>

            <p>
            Most water heater coils sold in India are priced between $2 to $20 USD.
            </p>

            <p>These products are low-cost, lightweight, and portable, offering a quick heating solution with no complex installation required.
            </p>
            <p>However, they pose significant safety risks, including electrical shocks and burns.</p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={atlantic2}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={atlantic5}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔑 Observations and field research</h3>

            <p>
              "One day I watched cricket on TV and I forgot the immersive rod in
              the bucket, it burnt the bucket" - Mitanshu - Pune
            </p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
              Insight: Forgetting to unplug the immersion rod can lead to safety hazards and wasted electricity.
              </p>
            </div>

            <p>
              "After use, it was hot, I putted it on the table and it burnt" -
              Anamika - Pune
            </p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
              Insight: Users struggle with safely storing the hot device after use.
              </p>
            </div>

            <p>
            Our research highlighted the immersion rod's simplicity and adaptability but also exposed significant pain points, particularly around safety and energy efficiency.
            </p>

            
            <div className="image_DG">
              <div className="imggauche">
                <img src={atlantic3}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={atlantic4}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🌞 Design and deliverables</h3>
            <p>
            Based on our findings, we focused on designing a product that is safe, easy to use, and adaptable to various user needs:
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={atlantic7}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={atlantic8}></img>
              </div>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p><b>Product Safety:</b> We enhanced the safety of the product by enclosing the coil in a solid protective cover and connecting it to a mobile app, allowing users to control the device remotely.</p>
            </div>

          
       
            <div className="arrow__title">
              <ArrowForward />
              <p><b>Energy Efficiency:</b> To prevent users from forgetting to unplug the device, we integrated features in the app to track daily energy consumption (kWh) and a timer to automatically turn off the device.</p>
            </div>

           
            <div className="image_DG">
              <div className="imggauche">
                <img src={atlanticgif}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={atlanticA}></img>
              </div>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={atlantic9}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={atlantic10}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <p>
           <b> This solution was selected for production and patented by Groupe Atlantic. It is currently in the process of being manufactured.</b>
            </p>

            <div style={{ marginTop: "24px" }}></div>
            <p>🖐 Collaboration : Aurore S. / Emma S. / Aditya S. / Pooja D.</p>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        </div>
      </div>
      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Atlantic;
