import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import "../Style/About.css";

function About() {
  return (
    <div className="about">
      <Header />
      <div className="content__about">
        <div className="container__about">
          <div className="container__texte">
            <h1>About me 🏡</h1>
            <p>
              I am a Senior UX Designer with 8+ years of experience, including 3
              years in finance products and 2 years in India, driving digital
              transformation through user-centered design.
            </p>
          </div>
        </div>
        <div style={{ marginTop: "100px" }}></div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
