import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import About from "./Pages/About";
import React from "react";
import Airindia from "./Pages/Airindia";
import Atlantic from "./Pages/Atlantic";
import Cnil from "./Pages/Cnil";
import Eram from "./Pages/Eram";
import Indemnite from "./Pages/Indemnite";
import Magnolia from "./Pages/Magnolia";
import Sncf from "./Pages/Sncf";
import Socgen from "./Pages/Socgen";
import Home from "./Pages/Home";



export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>


        <Route exact path="/about">
          <About />
        </Route>

        <Route exact path="/Airindia">
          <Airindia />
        </Route>

        <Route exact path="/Atlantic">
          <Atlantic />
        </Route>

        <Route exact path="/Cnil">
          <Cnil />
        </Route>

        <Route exact path="/Eram">
          <Eram />
        </Route>

        <Route exact path="/Indemnite">
          <Indemnite />
        </Route>

        <Route exact path="/Magnolia">
          <Magnolia />
        </Route>

        <Route exact path="/Sncf">
          <Sncf />
        </Route>

        <Route exact path="/Socgen">
          <Socgen />
        </Route>
      </Switch>
    </Router>
  );
}
