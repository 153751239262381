import React from "react";
import "../Style/Navigation.css";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";

function Navigation(props) {
  return (
    <div className="navigation">
      <div className="previous">
        <KeyboardArrowLeft />
        <a href={props.previousLink}>
          <p>Previous</p>
        </a>
      </div>

      <div className="next">
        <a href={props.nextLink}>
          <p>Next</p>
        </a>
        <KeyboardArrowRight />
      </div>
    </div>
  );
}

export default Navigation;
