import React, { useState, useEffect } from "react";
import "../Style/Gallery.css";
import Airindia from "../Images/thumbnail/Airindia.png";
import atlantic from "../Images/thumbnail/atlantic.jpg";
import cnil from "../Images/thumbnail/cnil.jpg";
import eram from "../Images/thumbnail/eram.jpg";
import indemnite from "../Images/thumbnail/indemnite.png";
import magnolia from "../Images/thumbnail/magnolia.png";
import retraite from "../Images/thumbnail/retraite.png";
import sncf from "../Images/thumbnail/sncf.jpg";

const products = [
  {
    id: 1,
    link: "/Socgen",
    title: "🌞 Retirement platform",
    imageUrl: retraite,
  },
  {
    id: 2,
    link: "/Magnolia",
    title: "🏠 Home insurance",
    imageUrl: magnolia,
  },
  {
    id: 3,
    link: "/Sncf",
    title: "🚐 Autonomous shared mobility",
    imageUrl: sncf,
  },
  {
    id: 4,
    link: "/Eram",
    title: "👟 Connected shoes",
    imageUrl: eram,
  },
  {
    id: 5,
    link: "/Indemnite",
    title: "💼 mesindemnites.com",
    imageUrl: indemnite,
  },
  {
    id: 6,
    link: "/Atlantic",
    title: "⚡ Immersive rod",
    imageUrl: atlantic,
  },
  {
    id: 7,
    link: "/Airindia",
    title: "✈ Booking flight",
    imageUrl: Airindia,
  },
  {
    id: 8,
    link: "/Cnil",
    title: "🔒 Data privacy",
    imageUrl: cnil,
  },
];

const Product = ({ product }) => (
  <div className="product">
    <a href={product.link}>
      <img src={product.imageUrl} alt={product.title} />
      <p className="title">{product.title}</p>
    </a>
  </div>
);

const ProductGrid = () => {
  const [numColumns, setNumColumns] = useState(window.innerWidth < 768 ? 1 : 4);
  const columnWidth = 100 / numColumns;

  useEffect(() => {
    const handleResize = () => {
      setNumColumns(window.innerWidth < 768 ? 1 : 4);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="product-grid">
      {products.map((product) => (
        <div
          key={product.id}
          className="product-cell"
          style={{ width: `${columnWidth}%` }}
        >
          <Product product={product} />
        </div>
      ))}
    </div>
  );
};

export default ProductGrid;
