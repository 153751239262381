import React from "react";
import "../Style/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="greyline"></div>

      <div className="links">
        <a href="mailto:alexisbretel@gmail.com">e-mail</a>
        <a href="https://www.linkedin.com/in/alexis9/">Linkedin</a>
      </div>
    </div>
  );
}

export default Footer;
