import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import retraite3 from "../Images/soge/retraite3.jpg";
import retraite4 from "../Images/soge/retraite4.jpg";
import retraite5 from "../Images/soge/retraite5.jpg";
import retraite6 from "../Images/soge/retraite6.gif";
import retraite7 from "../Images/soge/retraite7.jpg";
import retraite8 from "../Images/soge/retraite8.jpg";
import retraite9 from "../Images/soge/retraite9.jpg";

import { ArrowForward } from "@mui/icons-material";

function Socgen() {
  const navigationProps = {
    previousLink: "/",
    nextLink: "/Magnolia",
  };
  return (
    <div className="casestudy6">
      <div style={{ marginTop: "100px" }}></div>
      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2021</p>
          <p>Location : Paris - France</p>
          <p>
            Company : <a href="https://particuliers.sg.fr/">Société Générale</a>
          </p>
        </div>

        <h1>🌞 Retirement platform</h1>

        <h2>UI/UX Design</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={retraite6}></img>
        <div style={{ marginTop: "24px" }}></div>
        <div className="maintext">
          <div className="section1">
            <div style={{ marginTop: "24px" }}></div>
            <h3>🌎 Context</h3>

            <p>
              I worked on designing a retirement pension finance product
              integrated into the Société Générale banking app. This service
              targeted over 14 million clients aged between 40 and 60 years old.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🎯 Objective</h3>

            <p>
              01. Provide users with tools to anticipate and improve their
              financial plans for retirement.
            </p>
            <p>
              02. Help users estimate the income their wealth could generate at
              different retirement ages.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>💡 Ideation</h3>

            <p>
              We conducted several workshops to establish the user flow of the
              service, collaborating with two other UX designers, customer
              experience specialists, and the project manager.
            </p>

            <p>
              The user navigates through the onboarding process, answering a
              series of questions, before landing on the dashboard where they
              can view their estimated retirement pension results. To ensure
              reliability and update the dashboard, users can input data from
              three different scenarios.
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={retraite7}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={retraite4}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>💻 Prototype</h3>

            <p>
              Before creating the dynamic prototype, I developed wireframes
              based on the user flows: onboarding, various landing pages, and
              the dashboard.
              <br />
              <br />
              Then, I used design system components to create the UI for the
              following mobile and desktop screens:
            </p>

            <img src={retraite5}></img>
            <p>
              The user can change the retirement age (62-67) using the dropdown
              menu. Additionally, a conversational area on the side provides
              relevant information at the right time.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>📊 Usability testing</h3>
            <p>
              The main goals of the user testing were to validate the design
              choices, identify obstacles to usability, and test the ergonomics
              of the interface. The tests were conducted using an interactive
              Figma prototype that simulated various interface behaviors on both
              desktop and mobile devices.
            </p>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                We conducted individual sessions of 60 minutes each with 11
                Société Générale customers recruited from a panel.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                Participants included 9 women and 2 men aged between 40 and 62
                years. Six used the desktop version, and five used the mobile
                version.
              </p>
            </div>

            <p>
              <b>Types of evaluation:</b>{" "}
            </p>

            <p>
              Spontaneous: Immediate apprehension of the interface by the
              participant.
            </p>
            <p>
              Discovery: Participant’s analysis of the pages (e.g., searching
              for specific information).
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>📄 Results of the test</h3>

            <p>
              We applied various scoring criteria to analyze the service
              features. The interface received positive feedback, with no major
              changes required. Users described it as clean, clear, and easy to
              use.
            </p>

            <p>
              Given the complexity of retirement simulation, precise and
              distinct terminology is necessary to limit confusion and
              misunderstandings. We identified that some concepts were
              occasionally confusing for users.
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={retraite8}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={retraite9}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🌞 Design and deliverables</h3>

            <p>
              We delivered a product integrated into the existing banking app
              that provides retirement pension simulations and access to various
              solutions such as financial planning (retirement, business, home
              savings plan, life insurance), as well as the option to contact a
              Société Générale bank advisor.
            </p>

            <img src={retraite3}></img>
            <div style={{ marginTop: "24px" }}></div>
            <p>🖐 Société Générale Assurances</p>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        </div>
      </div>
      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Socgen;
