import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import magnolia1 from "../Images/magnolia/magnolia1.jpg";
import magnolia2 from "../Images/magnolia/magnolia2.jpg";
import magnolia5 from "../Images/magnolia/magnolia5.jpg";
import magnolia7 from "../Images/magnolia/magnolia7.jpg";
import magnolia8 from "../Images/magnolia/magnolia8.jpg";
import gif2 from "../Images/magnolia/gif2.gif";
import loader from "../Images/magnolia/loader.gif";

import { ArrowForward } from "@mui/icons-material";

function Magnolia() {
  const navigationProps = {
    previousLink: "/Socgen",
    nextLink: "/Sncf",
  };

  return (
    <div className="casestudy5">
      <div style={{ marginTop: "100px" }}></div>
      <Header />
      <Navigation {...navigationProps} />

      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2021</p>
          <p>Location : Paris - France</p>
          <p>
            Client : <a href="https://www.magnolia.fr">magnolia.fr</a>
          </p>
        </div>

        <h1>🏠 Home insurance</h1>

        <h2>UI Design</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={gif2}></img>
        <div style={{ marginTop: "24px" }}></div>
        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>
            <p>
              Magnolia.fr, a French insurance comparator, aimed to launch a new
              home insurance offering in partnership with Société Générale
              Assurances. I was responsible for designing the user interface for
              both the mobile and desktop versions of this service.
            </p>

            <div style={{ marginTop: "24px" }}></div>
            <h3>🎯 Objectives</h3>
            <p>
              01. Design the user interfaces for both mobile and desktop
              versions.
            </p>
            <p>
              02. Validate the interfaces with users through dynamic prototypes.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🌞 Design and deliverables</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b>Mobile-First UI Design:</b> Developed a user-centric
                interface designed primarily for mobile devices, with a
                responsive desktop version.
              </p>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={magnolia7}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={magnolia8}></img>
              </div>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b>Accessibility Enhancements:</b> Adjusted the initial brand
                color guidelines to optimize visibility and usability on digital
                screens.
              </p>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={magnolia2}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={magnolia1}></img>
              </div>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b>Additional UI Elements:</b> Created engaging visual elements
                including an animated loader and custom icons to enhance user
                experience.
              </p>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={magnolia5}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={loader}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🖐 Collaboration</h3>

            <p>
               Worked closely with the product owner, developers, business analysts, and the scrum master to ensure seamless integration and alignment with project goals.
            </p>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        </div>
      </div>

      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Magnolia;
