import React from "react";
import Header from "../Components/Header";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import "../Style/Casestudy.css";

import cnil1 from "../Images/cnil/cnil1.jpg";
import cnil2 from "../Images/cnil/cnil2.jpg";
import cnil3 from "../Images/cnil/cnil3.jpg";
import cnil4 from "../Images/cnil/cnil4.jpg";
import cnil5 from "../Images/cnil/cnil5.jpg";
import cnil6 from "../Images/cnil/cnil6.jpg";
import cnil7 from "../Images/cnil/cnil7.jpg";
import cnil8 from "../Images/cnil/cnil8.jpg";
import cnil9 from "../Images/cnil/cnil9.jpg";
import cnil10 from "../Images/cnil/cnil10.jpg";

import { ArrowForward } from "@mui/icons-material";

function Cnil() {
  const navigationProps = {
    previousLink: "/Airindia",
    nextLink: "/",
  };

  return (
    <div className="casestudy2">
      <div style={{ marginTop: "100px" }}></div>
      <Header />
      <Navigation {...navigationProps} />
      <div className="main__margin__layout">
        <div className="info">
          <p>Date : 2020</p>

          <p>Location : Paris - France</p>

          <p>
            Client : {""}
            <a href="https://www.cnil.fr/en/home">CNIL</a>
          </p>
        </div>

        <h1>🔒 Data privacy</h1>

        <h2>UX Research</h2>
        <div style={{ marginTop: "100px" }}></div>
        <img src={cnil1}></img>
        <div style={{ marginTop: "24px" }}></div>
        <div className="maintext">
          <div className="section1">
            <h3>🌎 Context</h3>
            <p>
              In 2020, I collaborated with CNIL (the French data protection
              authority) to equip designers with tools for creating interfaces
              dedicated to minors, helping them better understand and exercise
              their GDPR (General Data Protection Regulation) rights.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🎯 Objectives</h3>
            <p>
              01. Conduct a state-of-the-art analysis on personal data
              protection for minors.
            </p>
            <p>
              02. Collect qualitative data from minors to understand their needs
              concerning personal data.
            </p>
            <p>
              03. Provide designers with actionable tools to create more
              GDPR-compliant interfaces.
            </p>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔎 Research methodology</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b> Benchmarking:</b> Analyzed best and worst practices
                worldwide in privacy design for minors.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b> Focus Group Sessions: </b>Co-created solutions with
                designers, lawyers, and minors (aged 8-10, 11-14, 15-17), with a
                separate group for parents. CNIL observers were present at each
                stage.
              </p>
            </div>

            <div className="image_DG">
              <div className="imggauche">
                <img src={cnil9}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={cnil10}></img>
              </div>
            </div>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🔑 Observations and field research</h3>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b> Minors:</b> They represent about one-third of the world’s
                Internet users. Access to information about their rights is
                complex and not tailored to their specific needs. They
                understand "privacy" but struggle to connect their digital
                practices with personal data protection.
              </p>
            </div>

            <div className="arrow__title">
              <ArrowForward />
              <p>
                <b> Designers:</b> The challenge is to make legal information
                accessible and integrated seamlessly into digital interfaces for
                minors, balancing protection and empowerment.
              </p>
            </div>

            <div style={{ marginTop: "24px" }}></div>
            <h3>📦 Benchmark</h3>

            <p>
              <b>Bad Practices:</b> Common issues include overwhelming "walls of
              text," non-dismissible cookie banners, and "false accessibility"
              with complex texts despite visual aids.
            </p>
            <p>
              <b>Good Practices:</b> Examples include mimetic formats of main
              sites to maintain user experience continuity, adapted language,
              and recommendations from foundations like 5Rights.
            </p>

            <img src={cnil7}></img>
            <div style={{ marginTop: "24px" }}></div>
            <h3>👨‍👨‍👧 Focus Group</h3>

            <p>
              Through tailored co-creation activities, we drafted interfaces
              that align with minors' digital habits, using familiar graphics
              and UX elements.
            </p>

            <div className="image_DG">
              <div className="imggauche">
                <img src={cnil2}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={cnil3}></img>
              </div>
            </div>
            <div className="image_DG">
              <div className="imggauche">
                <img src={cnil4}></img>
              </div>
              <div className="mid"></div>
              <div className="imgdroite">
                <img src={cnil5}></img>
              </div>
            </div>
            <img src={cnil6}></img>
            <div style={{ marginTop: "24px" }}></div>
            <h3>🌞 Design and deliverables</h3>

            <p>
              Three key concepts clearly and simply illustrate fundamental
              privacy notions within interfaces for minors, with good practices
              and counter-examples. This solution was developed by CNIL and is
              available on their website{" "}
              <a href="https://design.cnil.fr/etudes-de-cas/brawl-crush/">
                here
              </a>
              .
            </p>

            <img src={cnil8}></img>
            <div style={{ marginTop: "24px" }}></div>
            <p>🖐 Collaboration : Amurabi agency, CNIL</p>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        </div>
      </div>

      <Navigation {...navigationProps} />
      <Footer />
    </div>
  );
}

export default Cnil;
